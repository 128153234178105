export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, expires, domain) {
  return document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domain}`;
}

export function deleteCookie(cname) {
  return document.cookie = `${cname}=; Max-Age=0; path=/; domain=` + window.location.host
}