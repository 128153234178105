
import { getCookie, setCookie, deleteCookie } from "./cookies";

const tokenCookieName = "gydaToken";
const gydaRememberCookieName = "gydaRemember";


export class RollingSessions {
  constructor({ resetTokenUrl, cookieDomain, logoutUrl }) {
    this.resetTokenUrl = resetTokenUrl;
    this.cookieDomain = cookieDomain;
    this.logoutUrl = logoutUrl;
  }

  getAuthToken() {
    const tokenCookie = getCookie(tokenCookieName);
    try {
      const token = JSON.parse(tokenCookie).token;
      return token;
    } catch (e) {
      console.log("Failed to get token from cookie", e);
      return null;
    }
  }

  resetCookie(cname, cvalue, domain) {
    const rememberCookie = getCookie(gydaRememberCookieName);
    let expirationInDays = 1;

    if (rememberCookie === "1") {
      expirationInDays = 7;
    }

    let expires = new Date();
    expires.setDate(expires.getDate() + expirationInDays);

    setCookie(cname, cvalue, expires, domain);
    setCookie(gydaRememberCookieName, rememberCookie, expires, domain);
  }

  initRollingSessionAndExecute(interval) {
    // Default to 5min but the end user can change that if they want
    const retryInterval = interval || 300000;

    // We call the function directly the first time so it will fire on page load. After this
    // we call it via setInterval to check every `setAmount` of time.
    this.resetToken();
    setInterval(this.resetToken.bind(this), retryInterval);
  }

  async resetToken() {
    let resetToken;

    try {
      const resetTokenResponse = await fetch(this.resetTokenUrl, {
        headers: {
          Authorization: this.getAuthToken()
        },
      });
      resetToken = await resetTokenResponse.json();

      if (resetToken.code === 401) {
        // "Delete" the cookies
        deleteCookie(tokenCookieName);
        deleteCookie(gydaRememberCookieName);

        // LOGOUT
        window.location.href = `${this.logoutUrl}?redirectTo=${encodeURIComponent(
          window.location.href
        )}`;
      }

      if (resetToken.token) {
        this.resetCookie(
          tokenCookieName,
          encodeURIComponent(JSON.stringify(resetToken)),
          this.cookieDomain
        );
      }
    } catch (e) {
      console.log("Failed to fetch authorization for current token:", e);
    }
  }
}