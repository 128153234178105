import { NavItemUtils } from './nav-item-utils';

export class ManifestNavigation {
    
    constructor(manifestInstance) {
        this.manifestInstance = manifestInstance;
    }
    
    async switchApp() {
        const manifest = await this.manifestInstance.getCurrentManifest();
        
        const newAppId = NavItemUtils.getActiveAppId(manifest.navigation);

        if (window.location.search.indexOf('unknownRoute=true') !== -1) {
            console.debug('Route already has unknownRoute in it; not continuing navigation');
            return;
        }

        if (this.manifestInstance.currentAppId !== newAppId) {
            window.location.reload();
        } else {
            if (window.location.search) {
                window.location.search += '&unknownRoute=true';
            } else {
                window.location.search = '?unknownRoute=true';
            }
        }
    }

    // Confirms that the user has access to the current path by checking
    // whether an entry exists in the nav data for it.  If one does not 
    // exist, it implies the user's access to this page is restricted
    // so we'll kick them to the 404 page
    async verifyAppAccess() {
        if(!this.manifestInstance.shouldVerifyAppAccess) {
            return;
        }

        // don't bother if we're on localhost.  assume we're doing development
        if(['localhost.meltwater.net', 'localhost.meltwater.com', 'localhost'].includes(window.location.hostname)) {
            return;
        }

        // don't bother if we're already on the unknown route page
        if(window.location.search.indexOf('unknownRoute=true') !== -1) {
            return;
        }

        // if we're at /, don't redirect immediately
        if(window.location.pathname === '/' || window.location.pathname === '') {
            return;
        }

        const manifest = await this.manifestInstance.getCurrentManifest();

        if(!manifest.unfilteredNavigation) {
            return;
        }

        if(NavItemUtils.isCurrentAppFiltered(manifest.unfilteredNavigation)) {
            if (window.location.search) {
                window.location.search += '&unknownRoute=true';
            } else {
                window.location.search = '?unknownRoute=true';
            }
        }
    }
}