import { ManifestClient } from './manifest';

let initializedResolve;
let hasInitialized = false;
let currentPayload = {
  treatments: {
    company: [],
    user: []
  }
}

let initialized = new Promise((resolve) => {
  initializedResolve = resolve;
})

export const Environments = {
  STAGING: 'staging',
  PRODUCTION: 'production'
}

export const Events = {
  MANIFEST_UPDATE: 'mi-manifest-update'
}

export const RefreshEventReasons = {
  USER_REQUESTED: 'user requested',
  DATA_EXPIRED: 'data expired',
  INTERNAL_REFRESH: 'internal refresh',
  RE_INITIALIZATION: 're-initialization'
}

export class Configuration {
  static getConfiguration(env) {
    if (env === Environments.STAGING) {
      return {
        baseUri: 'https://staging.meltwater.net/manifest',
        cookieDomain: '.meltwater.net',
        resetTokenUrl: 'https://live.staging.gaf-identity-provider.meltwater.io/auth/resetToken',
        logoutUrl: 'https://live.staging.gaf-identity-provider.meltwater.io/logout'
      }
    } else if (env === Environments.PRODUCTION) {
      return {
        baseUri: "https://app.meltwater.com/manifest",
        cookieDomain: ".meltwater.com",
        resetTokenUrl: "https://app.meltwater.com/auth/resetToken",
        logoutUrl: "https://app.meltwater.com/logout",
      };
    } else {
      throw new Error(`Configuration - unknown environment provided. Provided Value ${env}`);
    }
  }
}

export class Initializer {
  static initialize(env, payload, options = {}) {
    if (!hasInitialized) {
      const client = new ManifestClient(
        { ...Configuration.getConfiguration(env), ...options }
      );
      initializedResolve();
      hasInitialized = true;

      if (payload) {
        currentPayload = { ...currentPayload, ...client.payload };
      }

      client.navigation.verifyAppAccess();
      return;
    }
    return;

  }

  static onInitialized() {
    return initialized;
  }
}