import * as lzjs from 'lzjs';
import * as Cookies from 'cookies-js';

export const AppContainerStateChangeName = {
  initials : 'initials',
  company : 'company',
  navigation : 'navigation',
  isNavigationMinimized : 'isNavigationMinimized',
  helpText : 'helpText',
  pageName : 'pageName',
  pageSubName : 'pageSubName',
  footerLinks : 'footerLinks',
  isV2 : 'isV2'
}

// Singleton without a getInstance, just new it up
export class AppContainerState {

  constructor() {
    if(!!AppContainerState.instance) {
      return AppContainerState.instance;
    }
    AppContainerState.instance = this;
    this.setInitialState();
    return this;
  }

  setInitialState() {
    this.appContainerState = {};
    this.mwAppContainerStateCookieName = 'mwAppContainerState';
    let existingCookieString = Cookies.get(this.mwAppContainerStateCookieName);
    let existingCookieObject;

    if (existingCookieString) {
      try {
        existingCookieObject = JSON.parse(lzjs.decompressFromBase64(decodeURIComponent(existingCookieString)));
      } catch (err) {
        // Malformed JSON
      }
    }

    if (existingCookieObject) {
      this.appContainerState = existingCookieObject;
    } else {
      this.appContainerState = {
        initials: '',
        company: null,
        navigation: [],
        pageName: '',
        helpText: '',
        isNavigationMinimized: true,
        footerLinks: [],
        isV2: false
      };
    }
  }

  getIsNavigationMinimized() {
    return this.appContainerState.isNavigationMinimized;
  }

  updateAppContainerState(propertyName, payload) {
    if (window.location.pathname.indexOf('/switchingCompany') !== -1 ||
      window.location.pathname === '/reloadApplication') {
      return;
    }

    this.appContainerState[propertyName] = payload;

    this.setStateCookie();
  }

  removeStateCookie() {
    let host = window.location.hostname;
    Cookies.expire(this.mwAppContainerStateCookieName, { domain: host });
  }

  setStateCookie() {
    let stateCookie;

    try {
      stateCookie = encodeURIComponent(lzjs.compressToBase64(JSON.stringify(this.appContainerState)));
    } catch (err) {
      // Something went wrong with the stringify
      console.log('Error in manifest client attempting to stringify and compress appContainerState');
    }

    if (stateCookie) {
      let host = window.location.hostname;
      Cookies.set(this.mwAppContainerStateCookieName, stateCookie, { domain: host })
    }
  }
}