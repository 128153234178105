import { getCookie } from './cookies';
import { NavItemUtils } from './nav-item-utils';
import { ManifestNavigation } from './manifest-navigation';
import { Events, RefreshEventReasons } from './initializer';
import { Scheduler } from './scheduler';
import { RollingSessions } from "./rolling-sessions";

let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

export class ManifestClient {
    constructor({ baseUri, resetTokenUrl, cookieDomain, logoutUrl, shouldVerifyAppAccess = true }) {
        this.baseUri = baseUri;
        this.resetTokenUrl = resetTokenUrl;
        this.cookieDomain = cookieDomain;
        this.logoutUrl = logoutUrl;
        this.manifestPromise = null;
        this.token = this.getAuthToken('gydaToken');
        this.isInitialFetch = true;
        this.currentAppId = null;
        this.shouldVerifyAppAccess = shouldVerifyAppAccess;

        // Add sub-classes
        this.navigation = new ManifestNavigation(this);
        this.rollingSessions = new RollingSessions({ resetTokenUrl, cookieDomain, logoutUrl });

        instanceResolve(this);
    }

    async getCurrentManifest() {
        if (!this.manifestPromise) {
            if (window.location.pathname.indexOf('/switchingCompany') !== -1 ||
                window.location.pathname === '/reloadApplication') {
                this.manifestPromise = new Promise((resolve, reject) => { });
                return this.manifestPromise;
            }

            const isUserNavigation = !!(PerformanceNavigation &&
                PerformanceNavigation.TYPE_RELOAD &&
                performance &&
                performance.navigation &&
                performance.navigation.type === PerformanceNavigation.TYPE_NAVIGATE);

            let fetchRequest = {
                url: this.baseUri,
                options: {
                    headers: {
                        Authorization: this.token
                    }
                },
                method: 'GET'
            }

            this.manifestPromise = fetch(fetchRequest.url, fetchRequest.options)
                .then(async response => {
                    if (!response.ok) {
                        throw new Error(`Manifest - bad response from manifest endpoint`, {cause:{
                            statusCode: response.status || 'unknown status'}});
                    }
                    let manifest = await response.json();
                    return this.__supplementManifest(manifest);
                })
                .catch((error) => {
                    this.manifestPromise = null;
                    throw error;
                });
        }

        return this.manifestPromise.then(manifest => this.__supplementManifest(manifest));
    }

    

    async setNavigationAndTitleState(navigation) {
        let navItemsState = [];
        let title, subTitle;

        // Do not override the existing nav items if navItems length is 0
        // to avoid white flicker of nav items
        if (!navigation.length) {
            return;
        }

        for (let i = 0; i < navigation.length; i++) {
            let newNavItem = {
                icon: navigation[i].icon,
                isActive: navigation[i].isActive,
                pos: [navigation[i].group, navigation[i].positionInGroup]
            }

            navItemsState.push(newNavItem)

            // Also look for title and subtitle
            if (navigation[i].isActive) {
                title = navigation[i].translatedTitle;
            }
            if (navigation[i].subItems) {
                for (let j = 0; j < navigation[i].subItems.length; j++) {
                    if (navigation[i].subItems[j].isActive) {
                        title = navigation[i].translatedTitle;
                        subTitle = navigation[i].subItems[j].translatedTitle;
                    }
                }
            }
        }
    }

    getAuthToken() {
        const tokenCookie = getCookie('gydaToken');

        try {
            const token = JSON.parse(tokenCookie).token;
            return token;
        } catch (e) {
            console.log('Failed getting token from cookie');
            return null;
        }
    }

    __supplementManifest(manifest) {
        manifest.navigation = NavItemUtils.hydrateNavItems(manifest.navigation);
        manifest.navigation = NavItemUtils.mapNavigationResponseV2(manifest.navigation, manifest.navigationGroups)
        this.setNavigationAndTitleState(manifest.navigation);
        if (this.currentAppId === null) {
            this.currentAppId = NavItemUtils.getActiveAppId(manifest.navigation);
        }
        return manifest;
    }

    async __triggerEvent(eventType, reason) {
        const data = {
            timestamp: new Date(),
            detail: {
                reason: reason
            }
        };

        if (typeof CustomEvent === 'function') {
            // For sane modern browsers that support features released in the last decade
            let manifestUpdatedEvt = new CustomEvent(eventType, data);
            window.dispatchEvent(manifestUpdatedEvt);
        } else {
            // For certain other browsers...
            let manifestUpdatedEvt = document.createEvent('CustomEvent');
            manifestUpdatedEvt.initCustomEvent(eventType, true, true, data);
            window.dispatchEvent(manifestUpdatedEvt);
        }

    }

    static getInstance() {
        return instance;
    }
}
