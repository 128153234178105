import { ManifestClient } from './manifest';
import { Initializer, Environments, Events, RefreshEventReasons } from './initializer';
import { AppContainerState } from './app-container-state';

if (!window[EXTERNAL_NAME]) {
    window[EXTERNAL_NAME] = {
        ManifestClient,
        Initializer,
        Environments,
        Events,
        RefreshEventReasons,
        AppContainerState // This is no longer used but an empty class is kept to prevent breaking changes
    }
}
